import { graphql, Link, PageProps, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { darken } from 'polished'
import React from 'react'
import styled from 'styled-components'
import Container from '../../components/Container'
import Footer from '../../components/Footer/Footer'
import { Site } from '../../components/Site'
import { Main } from '../../components/Styled'
import { SEO } from '../../SEO'

const events = [
  {
    heading: 'daisee Announces New CEO and Bolstering of Management Team',
    subheading:
      'A reogranisation of roles to support the next phases of development and growth as the company continues its rapid expansion capitalising on recent success in the Insurance sector',
    date: '8 March 2022',
    href: '/news/management-changes',
    id: 'newCeo'
  }
]

const News: React.FC<PageProps> = props => {
  const images = useStaticQuery(graphql`
    query NewsPage {
      newCeo: file(relativePath: { eq: "ceo.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Site seo={{ ...SEO.news }} {...props}>
      <Main>
        <Container>
          <PageContent>
            <h1 className='heading-main'>News</h1>
            {events.map((event, i) => (
              <Link to={event.href} key={event.href}>
                <div className='event'>
                  <div className='grid'>
                    <div className='copy'>
                      <div className='inner'>
                        <p className='date'>{event.date}</p>
                        <h2 className='heading'>{event.heading}</h2>
                        <p className='subheading'>{event.subheading}</p>
                      </div>
                    </div>
                    <Img
                      style={{ maxHeight: '20rem' }}
                      imgStyle={{ objectFit: 'contain', padding: '1.5rem' }}
                      fluid={images[event.id].childImageSharp.fluid}
                    />
                  </div>
                </div>
              </Link>
            ))}
          </PageContent>
        </Container>
      </Main>
      <Footer />
    </Site>
  )
}

const PageContent = styled.div`
  margin: 9rem 0;

  .heading-main {
    margin: 0;
    font-weight: 500;
    font-size: 2.25rem;
    letter-spacing: -1.5px;
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.purple};
    margin-bottom: 3rem;
  }

  .event {
    border-radius: 1.5rem;
    background: ${({ theme }) => theme.colors.purple100};
    height: 20rem;
    overflow: hidden;
    transition: all 0.25s ease-in;

    &:hover {
      background: ${({ theme }) => darken(0.05, theme.colors.purple100)};
      box-shadow: ${({ theme }) => theme.boxShadow.small};
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left: 1.5rem;
    height: 100%;
    gap: 1rem;
  }

  .copy {
    display: grid;
    gap: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .inner {
    display: grid;
    gap: 1rem;
    height: fit-content;
  }

  .heading {
    font-weight: 500;
    font-size: 1.75rem;
    letter-spacing: -0.5px;
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.purple};
  }

  .subheading {
    font-weight: 400;
    font-size: 1.25rem;
    letter-spacing: -0.5px;
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.purple};
  }

  .event-time {
    font-size: 0.875rem;
  }

  .date {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.grey700};
    font-weight: 500;
  }

  ${({ theme }) => theme.breakpoints.tablet} {
    .event {
      height: 26rem;
    }
    .grid {
      padding: 0;
      grid-template-columns: 1fr;
    }

    .heading {
      font-size: 1.5rem;
    }

    .subheading {
      font-size: 1.125rem;
    }

    .copy {
      padding: 1.5rem;
    }
  }
`

const EventNumber = styled.div`
  align-self: end;
  color: ${({ theme }) => darken(0.1, theme.colors.purple100)};
  font-size: 2.5rem;
  font-weight: 600;
  letter-spacing: -1px;

  ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 1.5rem;
  }
`

export default News
